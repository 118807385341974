.buttonStyle {
  @apply border-2 
      border-[#d35400] 
      text-[#aeaeae] 
      w-full 
      max-w-xs 
      px-8 
      py-4 
      rounded-full 
      bg-black bg-opacity-70 
      md:bg-black md:bg-opacity-50
      hover:bg-[#d35400] 
      hover:text-white 
      transition-all;
}

.presentation {
  @apply text-[#313433] 
      text-4xl 
      font-bold 
      text-center 
      mt-4 
      md:text-3xl;
}

.LogoSize {
  @apply w-32 
      h-10 
      md:w-64 
      md:h-16
      sm:w-48
      sm:h-16;
}
